import moment from "moment";
import Swal from "sweetalert2";

export const emailRegex =
  /^[a-zA-Z0-9]+([._%+-]?[a-zA-Z0-9]+)*@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/;
export function formatDateWithTime(inputDateTime) {
  // Parse the input date string into a Date object
  const date = new Date(inputDateTime);

  // Get day, month, year, hour, minute, and second components
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  // const seconds = date.getSeconds();

  // Get the current year
  const currentYear = new Date().getFullYear();

  // Define an array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Format the date in the desired format: dd MonthName yyyy hh:mm:ss AM/PM
  let formattedDate = `${day} ${monthNames[monthIndex]},`;

  // Add year to formatted date if it's not the current year
  if (year !== currentYear) {
    formattedDate += ` ${year}`;
  }

  formattedDate += ` ${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
    hours < 12 ? "AM" : "PM"
  }`;

  return formattedDate;
}

// Function to format a Date object to a string in 'YYYY-MM-DDTHH:MM' format
export const formatDateTime = (date) => {
  const dateString = new Date(date);

  // Extract the full year (e.g., 2024)
  const year = dateString.getFullYear();

  // Extract the month (0-11), convert to a 2-digit string (e.g., '08' for August)
  // Adding 1 because getMonth() is zero-based
  const month = String(dateString.getMonth() + 1).padStart(2, "0");

  // Extract the day of the month, convert to a 2-digit string (e.g., '20' for the 20th)
  const day = String(dateString.getDate()).padStart(2, "0");

  // Extract the hours (0-23), convert to a 2-digit string (e.g., '14' for 2 PM)
  const hours = String(dateString.getHours()).padStart(2, "0");

  // Extract the minutes (0-59), convert to a 2-digit string (e.g., '05' for 5 minutes past the hour)
  const minutes = String(dateString.getMinutes()).padStart(2, "0");

  // Return the formatted date and time string in 'YYYY-MM-DDTHH:MM' format
  return `${year}-${month}-${day}T${hours}:${minutes}`;
};

// Function to calculate the start date (current date + 2 hours)
export const getStartDate = () => {
  const now = new Date();
  now.setHours(now.getHours() + 2); // Add 2 hours
  return formatDateTime(now);
};

// Function to calculate the end date (next day at the same time)
export const getEndDate = () => {
  const now = new Date();
  now.setDate(now.getDate() + 1); // Move to the next day
  return formatDateTime(now);
};

//to add ** in string such as password or secret key
export const maskedSecretKey = (string) => {
  const n = string.length;

  // splice the last four characters
  const lastFour = string.slice(-8);
  const remaining = string.slice(0, n - 8);

  // mask the remaining numbers with asterisks
  const masked = "*".repeat(remaining.length) + lastFour;

  return masked;
};

// Function to convert Date to ISO format
export function convertToISOFormat(dateString) {
  // Parse the input date string into a Date object
  const date = new Date(dateString);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    console.error("Invalid date string");
    return;
  }

  // Extract components for the ISO format
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");

  // Format the date in 'YYYY-MM-DDTHH:mm' format
  const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

  return isoFormattedDate;
}

//this can be used for restricing selecting past time
const filterPassedTime = (time) => {
  const currentDate = new Date();
  const selectedDate = new Date(time);

  return currentDate.getTime() < selectedDate.getTime();
};

export function parseDateWithCurrentYear(dateString, ISOFormat) {
  // Get the current year
  const currentYear = new Date().getFullYear();

  // Check if the year is missing in the input date string
  // \d{4} specifically looks for four consecutive digits in the string
  if (!/\d{4}/.test(dateString)) {
    // Append the current year to the date string
    dateString += `, ${currentYear}`;
  }

  if (ISOFormat) {
    const date = new Date(dateString);
    // Extract components for the ISO format
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Format the date in 'YYYY-MM-DDTHH:mm' format
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}`;

    return isoFormattedDate;
  } else {
    // Now create the date object
    return new Date(dateString);
  }
}

export const authorizedUser = (res) => {
  if (res?.code === 440) {
    localStorage.clear("p");
    window.location.href = `/auth/login?error=Your session has expired`;
  } else {
    return;
  }
};

//a function which converts UTC to local time of user
export const convertUTCToLocal = (utcDate) => {
  const isoFormattedDate = convertToISOFormat(utcDate); //first convert in ISO format
  return moment.utc(isoFormattedDate).local().format("D MMM YYYY, h:mm a"); // Convert UTC to local time
};

// Utility function to show the "Please Wait" warning when campaign is in progress
export const showInProgressAlert = () => {
  return Swal.fire({
    title: "Bulk Invite Sending in Progress",
    text: "Please wait until the process complete before performing this action.",
    icon: "info",
    timer: 4000,
    showConfirmButton: false,
  });
};

//make number comma seperated according to international number system
export const convertCommaSeperateNumToString = (num) => {
  return new Intl.NumberFormat("en-US").format(num);
};
