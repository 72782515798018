import { Modal, ModalBody, UncontrolledTooltip } from "reactstrap";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import { useEffect, useState, useRef } from "react";
import {
  cyanBlue,
  primaryConfigColor,
} from "../../../components/Common/constants";
import { useDispatch, useSelector } from "react-redux";
import { listEmailConfig } from "action/Settings/emailConfiguration";
import CustomPagination from "components/Common/Pagination";
import { deleteEmailConfigu } from "action/Settings/emailConfiguration";
import EmailConfigDetails from "./EmailConfigDetails";
import { useNavigate } from "react-router-dom";
import { setEmailConfigPrimary } from "action/Settings/emailConfiguration";
import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";

function ConfigTable({
  newRecordAdded,
  setNewRecordedAdded,
  scrollList,
  configListRef,
}) {
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
  });
  const [pageNum, setPageNum] = useState(1);

  const [showDetailsModal, setShowDetailsModal] = useState({
    show: false,
    ID: 0,
  });
  const emailConfigurationList = useSelector(
    (state) => state.EmailConfigReducers.emailConfigList
  );
  const toggleDetailsModal = () => setShowDetailsModal(!showDetailsModal.show);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNotificationOpenRef = useRef(false); // Create a ref to track open state
  const notificationAlertRef = useRef(null);
  const notify = (type, msgObj) => {
    if (isNotificationOpenRef.current) {
      return;
    } // Do nothing if notification is already open
    isNotificationOpenRef.current = true; // Set the notification as open
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {msgObj.message}
          </span>
          <span data-notify="message">{msgObj.subMessage}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    notificationAlertRef.current?.notificationAlert(options);
    // Automatically reset the notification state after the autoDismiss period
    setTimeout(() => {
      isNotificationOpenRef.current = false;
    }, 3000); // Match the autoDismiss time (3000ms = 3 seconds)
  };

  useEffect(() => {
    listEmailConfig(dispatch, pageNum);
  }, [pageNum]);

  useEffect(() => {
    if (newRecordAdded) {
      setShowListAlternative({
        loader: true,
        noRecordsFound: false,
      });
      if (pageNum === 1) {
        listEmailConfig(dispatch, 1);
      } else {
        setPageNum(1);
      }
      setNewRecordedAdded(false);
    }
  }, [newRecordAdded]);

  useEffect(() => {
    if (
      emailConfigurationList.EmailConfigurations &&
      emailConfigurationList.EmailConfigurations.length > 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (
      emailConfigurationList.EmailConfigurations &&
      emailConfigurationList.EmailConfigurations.length === 0
    ) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    }
    scrollList &&
      setTimeout(() => {
        configListRef.current?.scrollIntoView({ behavior: "smooth" });
      }, 500);
  }, [emailConfigurationList]);

  const confirmDeleteEmailConfig = async (ID) => {
    const res = await deleteEmailConfigu(ID);
    if (res.code === 200) {
      successDelete();
      if (
        emailConfigurationList.EmailConfigurations.length === 1 &&
        pageNum !== 1
      ) {
        const currPage = pageNum - 1;
        setPageNum(currPage);
      } else {
        listEmailConfig(dispatch, pageNum);
      }
    } else {
      notDeleted(res.msg);
    }
  };

  const successDelete = () => {
    Swal.fire({
      icon: "success",
      title: "Successfully Deleted!",
      showCancelButton: false,
      showConfirmButton: false,
      timer: 3000,
    });
  };

  const notDeleted = (msg) => {
    Swal.fire({
      title: "Something went wrong",
      text: msg ? msg : "",
      showConfirmButton: false,
      showCancelButton: false,
      icon: "error",
      timer: 3000,
    });
  };

  const deleteWarning = (row) => {
    Swal.fire({
      title: "Are you sure you want to delete this record?",
      icon: "info",
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Delete",
      html:
        row.isDefault === "YES"
          ? "<small>You are deleting the primary connection, set another email as primary.</small>"
          : "",
      focusCancel: true,
      customClass: {
        confirmButton: "bg-danger",
        cancelButton: "bg-light",
      },
      reverseButtons: true,
      didOpen: () => {
        // Remove focus from buttons
        const swal = Swal.getPopup();
        swal.querySelector(".swal2-confirm")?.blur();
        swal.querySelector(".swal2-cancel")?.blur();
      },
    }).then((result) => {
      if (result.isConfirmed) {
        showDeleteLoader();
        confirmDeleteEmailConfig(row.ID);
      }
    });
  };

  const showDeleteLoader = () => {
    Swal.fire({
      title: "Please wait...",
      html: '<i class="fa fa-spinner fa-spin fa-2xl m-5 text-info"></i>',
      showConfirmButton: false,
      allowOutsideClick: false,
    });
  };

  //on click of view details
  const handleConfigDetails = (ID) => {
    setShowDetailsModal({ show: true, ID: ID });
  };

  const handleConfigEdit = (ID) => {
    navigate("/a/email/edit", { state: { configID: ID } });
  };

  const makeEmailPrimaryConnection = async (row) => {
    const res = await setEmailConfigPrimary(row.ID, { isDefault: "YES" });
    if (res.code === 200) {
      notify("success", {
        message:
          "The email configuration has been set as the primary connection.",
        subMessage: res.message ? res.message : "",
      });
      listEmailConfig(dispatch, pageNum);
    } else {
      notify("error", {
        message: "Failed to set email configuration as the primary connection.",
        subMessage: res.message ? res.message : "",
      });
    }
  };
  return (
    <>
      <EmailConfigDetails
        showDetailsModal={showDetailsModal.show}
        toggleDetailsModal={toggleDetailsModal}
        emailConfigID={showDetailsModal.ID}
      />
      <h3 className="mb-0">Email Configurations</h3>
      {showListAlternative.loader ? (
        <div style={styles.loaderContainer}>
          <i
            className="fa fa-spinner fa-spin fa-2xl"
            style={styles.loaderColor}
          />
        </div>
      ) : showListAlternative.noRecordsFound ? (
        <div style={styles.loaderContainer}>No Records Found</div>
      ) : (
        <>
          <div className="rna-wrapper">
            <NotificationAlert ref={notificationAlertRef} />
          </div>
          <ToolkitProvider
            data={emailConfigurationList?.EmailConfigurations}
            keyField="ID"
            columns={[
              {
                dataField: "MailingService",
                text: "Mailing Service",
              },
              {
                dataField: "Title",
                text: "Title",
                formatter: (cell, row) => (
                  <div
                    className="clickable-text"
                    style={styles.title}
                    onClick={() => handleConfigDetails(row.ID)}
                  >
                    {row.Title}
                    {row.isDefault === "YES" && (
                      <span className="badge badge-success ml-3">Primary</span>
                    )}
                  </div>
                ),
              },
              {
                dataField: "CreatedBy",
                text: "Added By",
              },
              {
                dataField: "CreatedOn",
                text: "Created On",
              },
              {
                dataField: "Action",
                text: "Action",
                formatter: (cell, row) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      columnGap: "1rem",
                    }}
                  >
                    {row.ConfigType !== "FREE" ? (
                      <>
                        <div>
                          <a
                            className="table-action"
                            id={`tooltipConfigDetails${row.ID}`}
                            onClick={() => handleConfigDetails(row.ID)}
                            style={styles.cusrorPointer}
                          >
                            <i className="fas fa-info-circle" />
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={`tooltipConfigDetails${row.ID}`}
                          >
                            View Details
                          </UncontrolledTooltip>
                          <a
                            className="table-action table-action-primary-email"
                            id={`tooltipPrimaryConnection${row.ID}`}
                            onClick={() => {
                              if (row.isDefault !== "YES")
                                makeEmailPrimaryConnection(row);
                            }}
                            style={styles.cusrorPointer}
                          >
                            <i
                              className="fa-solid fa-circle-check"
                              style={{
                                color:
                                  row.isDefault === "YES" && primaryConfigColor,
                              }}
                            ></i>
                          </a>
                          <UncontrolledTooltip
                            delay={0}
                            target={`tooltipPrimaryConnection${row.ID}`}
                          >
                            {row.isDefault === "YES"
                              ? "Primary Connection"
                              : "Make Primary Connection"}
                          </UncontrolledTooltip>
                          {row.ConfigType !== "OAUTH_GOOGLE" ? (
                            <>
                              <a
                                className="table-action"
                                id={`tooltipConfigEdit${row.ID}`}
                                onClick={() => handleConfigEdit(row.ID)}
                                style={styles.cusrorPointer}
                              >
                                <i className="fas fa-edit" />
                              </a>
                              <UncontrolledTooltip
                                delay={0}
                                target={`tooltipConfigEdit${row.ID}`}
                              >
                                Edit Configuration
                              </UncontrolledTooltip>
                            </>
                          ) : (
                            <div
                              style={{
                                marginLeft:
                                  emailConfigurationList.EmailConfigurations.every(
                                    (item) =>
                                      item.ConfigType === "OAUTH_GOOGLE" ||
                                      item.ConfigType === "FREE"
                                  )
                                    ? "1rem"
                                    : "4.1rem",
                              }}
                            />
                          )}
                        </div>

                        <div className="text-gray">|</div>
                        <a
                          className="table-action table-action-delete mt-0"
                          id={`tooltipConfigDel${row.ID}`}
                          onClick={(e) => deleteWarning(row)}
                          style={styles.cusrorPointer}
                        >
                          <i className="fas fa-trash" />
                        </a>
                        <UncontrolledTooltip
                          delay={0}
                          target={`tooltipConfigDel${row.ID}`}
                        >
                          Delete Configuration
                        </UncontrolledTooltip>
                      </>
                    ) : null}
                  </div>
                ),
              },
            ]}
            search
          >
            {(props) => (
              <div className="py-4 table-responsive">
                <BootstrapTable
                  {...props.baseProps}
                  bootstrap4={true}
                  bordered={false}
                />
                <div>
                  <CustomPagination
                    totalPages={Math.ceil(
                      emailConfigurationList.totalRecords /
                        emailConfigurationList.noOfRecordsPerPage
                    )}
                    setPageNum={setPageNum}
                    activePage={pageNum}
                  />
                </div>
              </div>
            )}
          </ToolkitProvider>
        </>
      )}
    </>
  );
}

export default ConfigTable;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
  deleteLoader: {
    backgroundColor: "white",
    display: "flex",
    padding: "2rem",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    rowGap: "2rem",
  },
  title: {
    cursor: "pointer",
  },
};
