import { getCampaignList } from "action/campaign/campaignListing";
import { cyanBlue } from "components/Common/constants";
import { formatDateWithTime } from "components/CommonFunctions";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import EmailConfigList from "./EmailConfigList";
import CardsHeader from "components/Headers/CardsHeader";
import { Line } from "react-chartjs-2";
import SubUsersList from "./SubUsersList";
import { getDashboardData } from "action/Auth/dashboard";
import { statusColor1 } from "components/Common/constants";
import { convertUTCToLocal } from "components/CommonFunctions";
import Warning from "components/Common/Warning";
// import { getUpcomingCampaignList } from "action/Auth/dashboard";
import CalendarView from "pages/Calendar/Calendar";
// Colors
var colors = {
  gray: {
    100: "#f6f9fc",
    200: "#e9ecef",
    300: "#dee2e6",
    400: "#ced4da",
    500: "#adb5bd",
    600: "#8898aa",
    700: "#525f7f",
    800: "#32325d",
    900: "#212529",
  },
  theme: {
    default: "#172b4d",
    primary: "#5e72e4",
    secondary: "#f4f5f7",
    info: "#11cdef",
    success: "#2dce89",
    danger: "#f5365c",
    warning: "#fb6340",
  },
  black: "#12263F",
  white: "#FFFFFF",
  transparent: "transparent",
};

function Dashboard() {
  const navigate = useNavigate();
  const campaignList = useSelector(
    (state) => state.Dashboard.upcomingCampaignList
  );
  const dashboardData = useSelector((state) => state.Dashboard.dashboardData);
  const [showListAlternative, setShowListAlternative] = useState({
    loader: true,
    noRecordsFound: false,
    error: false,
  });
  const dispatch = useDispatch();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [window.innerWidth]);

  let chartExample3 = {
    options: {
      scales: {
        yAxes: [
          {
            gridLines: {
              color: "#ffffff", // Corrected color code
              zeroLineColor: "#000000", // Corrected color code
              borderDash: [5, 5],
              zeroLineBorderDash: [5, 5],
            },
          },
        ],
        xAxes: [
          {
            gridLines: {
              display: false,
            },
            ticks: {
              fontColor: "#000000", // Corrected color code
            },
          },
        ],
      },
      legend: {
        display: false,
      },
      responsive: true,
      maintainAspectRatio: false,
    },
    data: {
      labels: dashboardData?.invitesLineChart?.inviteDates || [], // Added fallback to empty array
      datasets: [
        {
          label: "Invites",
          data: dashboardData?.invitesLineChart?.invitesPerDays || [], // Added fallback to empty array
          borderColor: colors.theme?.primary || "#000", // Added fallback to avoid undefined error
          backgroundColor: colors.transparent || "rgba(0,0,0,0)", // Added fallback
          borderWidth: 2,
          pointBackgroundColor: colors.theme?.primary || "#000", // Added fallback
        },
      ],
    },
  };

  React.useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get("token");
    const name = searchParams.get("name");
    const role = searchParams.get("role");
    const error = searchParams.get("error");
    if (token && name && role) {
      const p = {
        token: token,
        name: name,
        role: role,
      };
      localStorage.setItem("p", JSON.stringify(p));
      // Remove token, name, and role from the URL
      searchParams.delete("token");
      searchParams.delete("name");
      searchParams.delete("role");
      window.history.replaceState(null, "", `${window.location.pathname}`);
    } else if (error) {
      window.location.href = `/auth/login?error=${error}`;
    }
  }, [window.location.search]);

  useEffect(() => {
    if (campaignList?.Campaign && campaignList?.Campaign?.length > 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
      });
    } else if (campaignList?.Campaign && campaignList?.Campaign?.length === 0) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: true,
      });
    } else if (campaignList?.code) {
      setShowListAlternative({
        loader: false,
        noRecordsFound: false,
        error: true,
      });
    }
  }, [campaignList]);
  useEffect(() => {
    // const filterParams = `&filtersJSON=[{"field": "StartAt", "condition": "gt", "filterValues": [""]}]`;
    // getUpcomingCampaignList(dispatch, 1, "", filterParams);
    getDashboardData(dispatch);
    getCampaignList(dispatch, 1);
  }, []);

  const formattedCampaignList = campaignList?.Campaign?.map((item, index) => {
    return {
      ...item,
      srNo: index + 1,
      createdAt: convertUTCToLocal(item.createdAt),
      startAt: item.startAt,
      endAt: item.endAt,
    };
  });

  return (
    <>
      {/* <SimpleHeader name="Company Name" /> */}
      <Warning />
      <CardsHeader dashboardCount={dashboardData.dashBoardCount} />
      <Container fluid className="mt--6">
        {/* Graph code start  */}
        <Row>
          <Col md={12} lg={12} sm={12}>
            <Card style={styles.cardStyle}>
              {" "}
              {/* Set fixed height */}
              <CardHeader>
                <h6 className="surtitle">Last 30 days</h6>
                <h5 className="h3 mb-0">Calendar Invites</h5>
              </CardHeader>
              <CardBody style={{ padding: "20px", position: "relative" }}>
                {" "}
                {/* Added padding */}
                <div className="chartjs" style={styles.chartStyle}>
                  {" "}
                  {/* Chart fits container */}
                  <Line
                    data={chartExample3.data}
                    options={chartExample3.options}
                    id="chart-invite"
                    className="chart-canvas"
                    style={{ width: "100%", height: "100%" }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {/* Graph code ended. */}

        {/* Email Config list start  */}
        <Row md={12} lg={12} sm={12}>
          <Col md={6} lg={6} sm={12}>
            <EmailConfigList
              emailConfigList={dashboardData.emailConfigurations}
            />
          </Col>
          {/* email config list end */}

          {/* Sub Users list start  */}
          <Col md={6} lg={6} sm={12}>
            <SubUsersList
              subUserList={dashboardData.activeAndInvitedSubUsers}
            />
          </Col>
        </Row>
        {/* Sub Users list end */}

        {/* Calendar component */}
        <Row md={12} lg={12} sm={12}>
          <Col md={12} lg={12} sm={12}>
            <CalendarView />
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Dashboard;

const styles = {
  cusrorPointer: { cursor: "pointer" },
  loaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "50vh",
  },
  loaderColor: { color: cyanBlue },
  chartStyle: {
    height: "100%",
    width: "100%",
    position: "relative",
  },
  cardStyle: { height: "400px", overflow: "hidden" },
};
