import { upcomingCampaignList } from "constants/dashboard";
import { dashboardData } from "constants/dashboard";

const initialState = {
  dashboardData: {},
  upcomingCampaignList: {},
};

const Dashboard = (state = initialState, action) => {
  switch (action.type) {
    case dashboardData:
      return { ...state, dashboardData: action.payload };

    case upcomingCampaignList:
      return { ...state, upcomingCampaignList: action.payload };
    default:
      return state;
  }
};

export default Dashboard;
