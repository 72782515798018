import { organizationDetails } from "constants/SettingConstants/organization";
import * as api from "../../API/index";
import { countryList } from "constants/SettingConstants/organization";
import { authorizedUser } from "components/CommonFunctions";

export const getOrganizationDetail = async (dispatch) => {
  try {
    const res = await api.get_organization_details();
    dispatch({ type: organizationDetails, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    console.log(error);
  }
};

export const updateTenant = async (data) => {
  try {
    const res = await api.update_tenant(data);
    return res.data;
  } catch (error) {
    authorizedUser(error.response.data);
    return error.response.data;
  }
};

export const getCountryList = async (dispatch) => {
  try {
    const res = await api.country_list();
    dispatch({ type: countryList, payload: res.data.data });
  } catch (error) {
    dispatch({ type: countryList, payload: error.response.data });
  }
};

// export const poweredByFlagChange = async (data) => {
//   try {
//     const res = await api.powered_by_flag_change(data);
//     return res;
//   } catch (error) {
//     return error;
//   }
// };
