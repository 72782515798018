import EmailConfiguration from "pages/Settings/EmailConfig/EmailConfiguration";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

function EmailConfigModal({
  isOpen,
  toggleAddConfigModal,
  notify,
  createCampaignFields,
  campaignCode,
}) {
  return (
    <Modal isOpen={isOpen} size="lg">
      <ModalHeader toggle={() => toggleAddConfigModal(false)}>
        Add Email Configuration
        <p className="m-0 mt-1" style={{ fontSize: "0.9rem" }}>
          <span>
            <i className="fa-solid fa-lock mr-1 text-green"></i>
          </span>
          The email configurations are securely stored using 256-bit AES-GCM
          encryption.
        </p>
      </ModalHeader>
      <ModalBody className="p-2 pb-4">
        <EmailConfiguration
          fromCalendar={true} //this flag taken to understand that that component is used for calendar modal
          toggleAddConfigModal={toggleAddConfigModal}
          modalNotify={notify}
          createCampaignFields={createCampaignFields}
          campaignCode={campaignCode}
        />
      </ModalBody>
    </Modal>
  );
}

export default EmailConfigModal;
