import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import ErrorDisplay from "../CreateCampaign/ErrorsDisplay";
import { useState } from "react";
import { CREATE_CAMPAIGN_STEP2 } from "API/apiConstants";
import { getCampaignDetails } from "action/campaign/createCampaign";

function ImportBulkAttendeeModal({
  isBulkModalOpen,
  toggleBulkModal,
  notify,
  campaignCode,
  getAttendeeList,
  pageNum,
  setPageNum,
  dispatch,
  filterParams,
  sortParam,
}) {
  const [selectedCsv, setSelectedCsv] = useState(null);
  const [updateExisting, setUpdateExisting] = useState("NO");
  const [showLoaderOnClick, setShowLoaderOnClick] = useState(false);
  const [errors, setErrors] = useState({ data: [], message: "" });

  //on select of file
  const handleSelectCsv = (e) => {
    if (e.target.files && e.target.files[0]) {
      // Convert file size to MB
      const fileSizeInMB = e.target.files[0].size / (1024 * 1024);
      // Check if file size exceeds 50MB
      if (fileSizeInMB > 50) {
        notify("error", {
          message: "File with a maximum size of 50MB is allowed",
          subMessage: "",
        });
        // alert(
        //   "File with a maximum size of 50MB is allowed"
        // );
        setSelectedCsv(null);
      } else {
        setSelectedCsv(e.target.files[0]);
        //remove error
        setErrors({ data: [], message: "" });
      }
    }
    // Reset the input value to allow selecting the same file again
    e.target.value = "";
  };

  //on click of download file
  const handleDownload = () => {
    // Path to your CSV file
    const filePath = require("../../../assets/img/Misc/import attendee sample.csv");

    // Create a link element
    const link = document.createElement("a");
    link.href = filePath;

    // Set the download attribute to suggest a filename
    link.download = "sample.csv";

    // Append the link to the body (necessary for Firefox)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Clean up the link by removing it from the DOM
    document.body.removeChild(link);
  };

  //Import file upload
  const handleCreateCampaignStep2 = async () => {
    let headers = new Headers();
    const p = JSON.parse(localStorage.getItem("p"));
    headers.append("token", p.token);
    //  headers.append("Content-Type", "multipart/form-data");
    const formData = new FormData();
    formData.append("file", selectedCsv);
    formData.append("updateRecords", updateExisting);
    await fetch(`${CREATE_CAMPAIGN_STEP2}${campaignCode}`, {
      method: "POST",
      headers: headers,
      body: formData,
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.code === 200) {
          notify("success", {
            message: res.message || "Attendee Imported Sucessfully!",
            subMessage: "",
          });
          setSelectedCsv(null);
          getCampaignDetails(dispatch, campaignCode);

          if (pageNum === 1) {
            // if it is on page on only then called attedee list with applied params of search or sort if any
            getAttendeeList(
              dispatch,
              campaignCode,
              pageNum,
              filterParams, //got via props
              sortParam //got via props
            );
          } else {
            //on import to make sure user able to see the records set page 1 or
            setPageNum(1);
          }
          if (res?.data?.errorLink) {
            setErrors({ data: res.data, message: res.message });
          } else {
            toggleBulkModal();
          }
        } else if (res.code === 400) {
          setErrors({ data: res.data, message: res.message });
        } else {
          notify("error", {
            message: res.message ? res.message : "Something went wrong",
            subMessage: "Please check the CSV file.",
          });
        }
        setShowLoaderOnClick(false);
      });
  };

  return (
    <Modal isOpen={isBulkModalOpen} size="lg">
      <ModalHeader
        toggle={() => {
          //on close reset the state and toggle the modal
          setSelectedCsv(null);
          setUpdateExisting("NO");
          setErrors({ data: [], message: "" });
          toggleBulkModal();
        }}
      >
        Import Attendees
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col>
            <FormGroup>
              <Label className="form-control-label">
                Select CSV file to Upload
              </Label>

              <div className="custom-file">
                <input
                  type="file"
                  accept=".csv, text/csv"
                  style={{ display: "none" }}
                  onChange={(e) => {
                    handleSelectCsv(e);
                  }}
                  id="csvFile"
                />
                <label htmlFor="csvFile" className="custom-file-label">
                  {selectedCsv ? selectedCsv.name : "Choose CSV file"}
                </label>
              </div>
              <div style={styles.checkboxAndText}>
                <i
                  className={
                    updateExisting === "YES"
                      ? "fa-solid fa-square-check fa-md text-primary"
                      : "fa-regular fa-square fa-md"
                  }
                  onClick={() =>
                    setUpdateExisting(updateExisting === "YES" ? "NO" : "YES")
                  }
                  style={{ cursor: "pointer" }}
                ></i>
                <Label
                  check
                  className="form-control-label"
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setUpdateExisting(updateExisting === "YES" ? "NO" : "YES")
                  }
                >
                  Update Existing Attendee Details
                </Label>
              </div>
              <div className="mt-2">
                <p style={{ fontSize: "0.9rem" }}>
                  Note: You can upload 100,000 records in one calendar and add
                  verified email addresses please
                </p>
                <p
                  className="text-primary font-weight-600 "
                  style={styles.csvExampleInnerText}
                  onClick={() => handleDownload()}
                >
                  <i className="fa fa-download mr-2"></i>
                  Download a sample CSV file
                </p>
              </div>
            </FormGroup>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center">
          <Button
            color="info"
            onClick={() => {
              handleCreateCampaignStep2();
              setShowLoaderOnClick(true);
            }}
            disabled={selectedCsv === null || showLoaderOnClick}
          >
            {showLoaderOnClick ? (
              <i className="fa fa-spinner fa-spin fa-lg text-white" />
            ) : (
              "Import"
            )}
          </Button>
        </Row>
        {errors.message && (
          <ErrorDisplay errors={errors.data} title={errors.message} />
        )}
      </ModalBody>
    </Modal>
  );
}

export default ImportBulkAttendeeModal;

const styles = {
  checkboxAndText: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    columnGap: "0.5rem",
    marginTop: "0.5rem",
  },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
};
