// ##############################
// // // data for AddEditAttendee Modal
// #############################

export const attendeeListDropdown = [
  { label: "Pending", value: "UNSEND" },
  { label: "Sent", value: "SENT" },
  { label: "Don't Send", value: "DONT_SEND" },
  { label: "Unsubscribed", value: "UNSUBSCRIBED" },
  { label: "Failed", value: "FAILED" },
];

export const mapData = [
  {
    label: "Attendee Email",
    maxLength: 100,
    name: "email",
    errMsg: "Please enter a valid email address.",
  },
  {
    label: "First Name",
    maxLength: 50,
    errMsg: "First name should contain only alphabets and cannot be empty.",
    name: "firstName",
  },
  {
    label: "Last Name",
    maxLength: 50,
    errMsg: "Last name should contain only alphabets and cannot be empty.",
    name: "lastName",
  },
  {
    label: "Field1",
    maxLength: 255,
    name: "field1",
    errMsg: "Field1 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field2",
    maxLength: 255,
    name: "field2",
    errMsg: "Field2 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field3",
    maxLength: 255,
    name: "field3",
    errMsg: "Field3 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field4",
    maxLength: 255,
    name: "field4",
    errMsg: "Field4 cannot be empty. Please enter a valid value.",
  },
  {
    label: "Field5",
    maxLength: 255,
    name: "field5",
    errMsg: "Field5 cannot be empty. Please enter a valid value.",
  },
];

// ##############################
// // // data for Invite Log
// #############################

const data = [
  {
    srNo: 1,
    name: "John Doe",
    office: "Acme Corp",
    position: "12345",
    time: "10:30 AM",
    type: "Success",
    credit: 1,
    massage: "Welcome to the team!",
    ipAddress: "192.168.1.1",
    createdBy: "Admin",
    createdOn: "2024-08-01",
  },
  {
    srNo: 2,
    name: "Jane Smith",
    office: "Beta Inc",
    position: "67890",
    time: "02:15 PM",
    type: "Success",
    credit: 1,
    massage: "Thank you for your contribution.",
    ipAddress: "192.168.1.2",
    createdBy: "Admin",
    createdOn: "2024-08-02",
  },
  {
    srNo: 3,
    name: "Alice Johnson",
    office: "Gamma LLC",
    position: "11223",
    time: "08:45 AM",
    type: "Success",
    credit: 1,
    massage: "Please review the attached report.",
    ipAddress: "192.168.1.3",
    createdBy: "Admin",
    createdOn: "2024-08-03",
  },
  {
    srNo: 4,
    name: "Bob Brown",
    office: "Delta Ltd",
    position: "44556",
    time: "05:00 PM",
    type: "Success",
    credit: 1,
    massage: "Meeting scheduled for next week.",
    ipAddress: "192.168.1.4",
    createdBy: "Admin",
    createdOn: "2024-08-04",
  },
  {
    srNo: 5,
    name: "Emily Davis",
    office: "Epsilon Enterprises",
    position: "78901",
    time: "11:00 AM",
    type: "Success",
    credit: 1,
    massage: "Please confirm the new schedule.",
    ipAddress: "192.168.1.5",
    createdBy: "Admin",
    createdOn: "2024-08-05",
  },
  {
    srNo: 6,
    name: "Michael Green",
    office: "Zeta Solutions",
    position: "23456",
    time: "09:30 AM",
    type: "Success",
    credit: 1,
    massage: "Reminder: Complete your tasks.",
    ipAddress: "192.168.1.6",
    createdBy: "Admin",
    createdOn: "2024-08-06",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
  {
    srNo: 7,
    name: "Samantha Wilson",
    office: "Eta Tech",
    position: "34567",
    time: "03:00 PM",
    type: "Success",
    credit: 1,
    massage: "Final call for project updates.",
    ipAddress: "192.168.1.7",
    createdBy: "Admin",
    createdOn: "2024-08-07",
  },
  {
    srNo: 8,
    name: "Daniel Carter",
    office: "Theta Innovations",
    position: "45678",
    time: "12:30 PM",
    type: "Success",
    credit: 1,
    massage: "Document review is complete.",
    ipAddress: "192.168.1.8",
    createdBy: "Admin",
    createdOn: "2024-08-08",
  },
];

export default data;

//the drop down list for selecting event type
const eventTypeList = [
  { value: "ONLINE", label: "Online" },
  { value: "OFFLINE", label: "Offline" },
];

// for showing the dynamic calendar parameter on the step 1 on right
const calendarParameter = [
  // { label: "Email Subject", value: "{email-subject}" },
  { label: "Event Start Date", value: "{event-start-datetime}" },
  { label: "Event End Date", value: "{event-end-datetime}" },
  { label: "Unsubscribe Link", value: "{unsubscribe-link}" },
];

// for showing the attendee calendar parameter on the step 1 on right
const attendeeParameter = [
  { label: "Attendee Email", value: "{attendee-email}" },
  { label: "First Name", value: "{first-name}" },
  { label: "Last Name", value: "{last-name}" },
  { label: "Field1", value: "{field1}" },
  { label: "Field2", value: "{field2}" },
  { label: "Field3", value: "{field3}" },
  { label: "Field4", value: "{field4}" },
  { label: "Field5", value: "{field5}" },
];

const labelToKeyMap = {
  "First Name": "firstName",
  "Last Name": "lastName",
  "Attendee Email": "email",
  Field1: "field1",
  Field2: "field2",
  Field3: "field3",
  Field4: "field4",
  Field5: "field5",
};

//dropdown list for selecting more columns
const columnsList = [
  { label: "Field1", value: "field1" },
  { label: "Field2", value: "field2" },
  { label: "Field3", value: "field3" },
  { label: "Field4", value: "field4" },
  { label: "Field5", value: "field5" },
];

export {
  eventTypeList,
  calendarParameter,
  attendeeParameter,
  columnsList,
  labelToKeyMap,
};
