import { downloadErrorCsv } from "action/campaign/createCampaign";
import React, { useState } from "react";
import Swal from "sweetalert2";

//this component is used to show multiple errors for the csv file error
const ErrorDisplay = ({ errors, title }) => {
  const [downloading, setDownloading] = useState(false);

  //to download the csv file
  const handleDownloadErrorCSV = async () => {
    const res = await downloadErrorCsv(errors.errorLink);
    if (res && res.data) {
      // Step 1: Create a Blob from the response data
      const csvBlob = new Blob([res.data], { type: "application/csv" });
      // Step 2: Create a temporary link to trigger the download
      const url = window.URL.createObjectURL(csvBlob);
      // Creates a new <a> element dynamically
      const link = document.createElement("a");
      // Sets the link's href to the Blob URL
      link.href = url;
      // Specifies the file name that the browser will use for the download
      link.setAttribute("download", "invalid_data.csv");
      // Adds the link to the DOM temporarily so it can be clicked programmatically
      document.body.appendChild(link);
      // Triggers a click event on the link, which starts the download.
      link.click();
      // Clean up by removing the link and revoking the object URL
      document.body.removeChild(link); // Removes the link from the DOM after the download starts.
      window.URL.revokeObjectURL(url); //Frees up memory used by the Blob URL.
    } else {
      Swal.fire({
        title: "Something Went Wrong!",
        text: `Failed to download the error csv file.`,
        icon: "error",
        showConfirmButton: false,
        timer: 3000,
      });
    }
  };

  return (
    <>
      {errors && errors.length > 0 ? (
        <div style={styles.errorContainer}>
          <h2 style={styles.errorTitle}>{title}</h2>
          <div style={styles.errorListContainer}>
            <ul style={styles.errorList}>
              {errors.map((error, index) => (
                <li key={index} style={styles.errorItem}>
                  {error}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ) : (
        //made the error look different when its only a single error
        <div style={styles.singleError}>
          <p style={styles.errorTitleSingle}>{title}</p>
          {errors && errors.errorLink && (
            <>
              {downloading ? (
                <p
                  className="text-muted font-weight-600 "
                  style={styles.csvExampleInnerText}
                >
                  <i className="fa fa-spinner fa-spin mr-2"></i>
                  Downloading
                </p>
              ) : (
                <span
                  className="text-primary font-weight-600"
                  style={styles.csvExampleInnerText}
                  onClick={() => handleDownloadErrorCSV()}
                >
                  <i className="fa fa-download mr-2"></i>
                  Download Error CSV File
                </span>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default ErrorDisplay;

const styles = {
  errorContainer: {
    backgroundColor: "#ffffff",
    border: "0.0625rem solid #e0b4b4",
    padding: "1.25rem",
    borderRadius: "0.5rem",
    maxWidth: "100%",
    margin: "1.25rem auto",
    boxShadow: "0 0.25rem 0.625rem rgba(0, 0, 0, 0.1)",
  },
  errorTitle: {
    color: "#d8000c",
    fontSize: "1.125rem",
    marginBottom: "0.9375rem",
  },
  errorListContainer: {
    maxHeight: "18.75rem",
    overflowY: "auto",
    paddingRight: "0.625rem",
  },
  errorList: {
    listStyleType: "none",
    padding: 0,
  },
  errorItem: {
    backgroundColor: "#fff3f3",
    color: "#d8000c",
    padding: "0.625rem",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    fontSize: "0.875rem",
    border: "0.03125rem solid #e0b4b4",
  },
  singleError: {
    margin: "1.25rem auto",
    backgroundColor: "#fff3f3",
    color: "#d8000c",
    padding: "0.625rem",
    marginBottom: "0.5rem",
    borderRadius: "0.25rem",
    fontSize: "0.875rem",
    border: "0.03125rem solid #e0b4b4",
    textAlign: "center",
  },
  csvExampleInnerText: { cursor: "pointer", fontSize: 14 },
  errorTitleSingle: {
    color: "#d8000c",
    marginBottom: "0.9375rem",
  },
};
