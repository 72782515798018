import React from "react";
import { Container, Row } from "reactstrap";

function Warning() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  React.useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="pb-6 mt--6">
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center">
              {windowWidth <= 576 ? (
                <>
                  <div className="scrollingContainer">
                    <div className="scrollingWarning">
                      This application is easier to see and use on a desktop
                      computer.
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
}
export default Warning;
