import { reset_profile_password } from "API";

export const resetProfilePassword = async (passwords) => {
  try {
    const res = await reset_profile_password(passwords);
    return res;
  } catch (error) {
    return error;
  }
};
