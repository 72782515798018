import { authorizedUser } from "components/CommonFunctions";
import * as api from "../../API/index";
import { dashboardData } from "constants/dashboard";
import { upcomingCampaignList } from "constants/dashboard";

export const getDashboardData = async (dispatch) => {
  try {
    const res = await api.get_dashboard_data();
    dispatch({ type: dashboardData, payload: res.data.data });
  } catch (error) {
    authorizedUser(error.response.data);
    dispatch({ type: dashboardData, payload: error });
  }
};

export const getUpcomingCampaignList = async (
  dispatch,
  pageNo,
  sortParam,
  filterParams
) => {
  try {
    const res = await api.get_campaign_list(pageNo, sortParam, filterParams);
    dispatch({ type: upcomingCampaignList, payload: res.data.data });
  } catch (error) {
    dispatch({ type: upcomingCampaignList, payload: error });
    authorizedUser(error.response?.data);
  }
};
