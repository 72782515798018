/*!

=========================================================
* Argon Dashboard PRO React - v1.2.5
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { useState } from "react";
// react library for routing
import { useLocation, useNavigate } from "react-router-dom";
// reactstrap components
import { NavbarBrand, Navbar, NavItem, Nav, Container } from "reactstrap";

function AdminNavbar() {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = location;
  const [login, setLogin] = useState(searchParams.pathname === "/auth/login");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  React.useEffect(() => {
    setLogin(searchParams.pathname === "/auth/login");
  }, [searchParams]);
  React.useEffect(() => {
    // Gets the search params (e.g., "?error=xyz")
    if (login) {
      if (searchParams.search) {
        return;
      } else {
        navigate("/auth/login");
      }
      navigate("/auth/login");
    } else {
      navigate("/auth/register");
    }
    //Done to make the text and logo responsive
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [login]);

  const styles = {
    container: {
      display: "flex",
      alignContent: "center",
      justifyContent: "center",
    },
    image: {
      width: windowWidth <= 576 ? "40px" : windowWidth <= 768 ? "50px" : "65px",
      height:
        windowWidth <= 576 ? "35px" : windowWidth <= 768 ? "45px" : "59px",
    },
    text: {
      fontFamily: "rubik",
      color: "white",
      fontSize:
        windowWidth <= 576 ? "24px" : windowWidth <= 768 ? "28px" : "36px",
      fontWeight: 500,
      textTransform: "none",
      marginLeft: "5px", // Spacing between image and text
      marginBottom: 0, // Ensure no extra margin below the text
      marginTop: "10px",
    },
    login: { cursor: "pointer", borderRadius: "3px" },
  };
  return (
    <>
      <Navbar
        className="navbar-horizontal navbar-main navbar-dark navbar-transparent"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand>
            <div style={styles.container} className="align-items-center d-flex">
              <img
                alt="..."
                className="navbar-brand-img align-items-center mt-2 d-flex"
                src={require("assets/img/brand/bulk-logo.png")}
                style={styles.image}
              />
              <p style={styles.text}>BulkCalendar</p>
            </div>
          </NavbarBrand>
          <Nav className="align-items-lg-center ml-lg-auto" navbar>
            <NavItem>
              <span
                className="nav-link-inner text-white border solid pr-4 pl-4 pt-2 pb-2"
                style={styles.login}
                onClick={() => setLogin(!login)}
              >
                {login ? "Register" : "Login"}
              </span>
            </NavItem>
          </Nav>
          {windowWidth <= 576 ? (
            <>
              <div className="scrollingContainer">
                <div className="scrollingWarning">
                  This application is easier to see and use on a desktop
                  computer.
                </div>
              </div>
            </>
          ) : (
            ""
          )}
        </Container>
      </Navbar>
    </>
  );
}

export default AdminNavbar;
