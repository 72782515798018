import { getAttendeeList } from "action/campaign/createCampaign";
import {
  getCampaignDetails,
  sendCampaign,
} from "action/campaign/createCampaign";
import { convertCommaSeperateNumToString } from "components/CommonFunctions";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody, ModalHeader, Button, Table, Col } from "reactstrap";
import Swal from "sweetalert2";

export const BulkSendCalendarInviteModal = ({
  availableCredits,
  creditsToBeUsed,
  numberOfRecipients,
  notify,
  campaignCode,
  dispatch,
  navigate,
}) => {
  const [modal, setModal] = useState(false);
  const [creditsInsuficientModal, setCreditsInsuficientModal] = useState(false);
  const [showLoaderOnSend, setShowLoaderOnSend] = useState(false);
  const attendeeListFilters = useSelector(
    (state) => state.createCampaign.appliedFiltersOnAttendees
  );
  const toggle = () => setModal(!modal);
  const toggleCreditsModal = () =>
    setCreditsInsuficientModal(!creditsInsuficientModal);

  const handleSend = async () => {
    const res = await sendCampaign(campaignCode);
    if (res.code === 200) {
      //call to get campaign when calendar Invite sent successfully
      //3rd parameter send as true to return the response
      const getCampRes = await getCampaignDetails(dispatch, campaignCode, true);
      if (getCampRes.status === "COMPLETED") {
        Swal.fire({
          title: "Calendar invites have been successfully sent!",
          icon: "success",
          showConfirmButton: true,
          customClass: {
            confirmButton: "bg-info",
            closeButton: "custom-close-button", // Apply custom class to the close button
          },
          allowOutsideClick: false,
          showCloseButton: true,
        });

        //to show notify messsage when campaign gets falied just after sending is done
      } else if (getCampRes.status === "FAILED") {
        Swal.fire({
          title: "Sending Failed!",
          text: getCampRes?.failedRemark || "Something went wrong",
          icon: "error",
          showConfirmButton: true,
          html: `<span>
            ${getCampRes?.failedRemark || "Something went wrong"}
              <br/><br/>
            <span style="display: block; text-align: justify; font-size: 14px; color: #6c757d;"> <!-- Muted color -->
           Ensure the selected email configuration is valid and hasn't reached its daily limit; if exhausted, choose a new configuration and try again.
            </span>
          </span>`,
          customClass: {
            confirmButton: "bg-info",
            closeButton: "custom-close-button", // Apply custom class to the close button
          },
          allowOutsideClick: false,
          showCloseButton: true,
        });
      }
      // called attendee list with applied params of search or sort  abd page if any
      getAttendeeList(
        dispatch,
        campaignCode,
        attendeeListFilters.pageNum, // got via redux
        attendeeListFilters.filterParams, //got via redux
        attendeeListFilters.sortParam //got via redux
      );
    } else {
      Swal.fire({
        title: res.message ? res.message : "Error sending calendar invite!",
        text: res.message ? "" : "Please try again",
        icon: "error",
        showConfirmButton: true,
        customClass: {
          confirmButton: "bg-info",
          closeButton: "custom-close-button", // Apply custom class to the close button
        },
        allowOutsideClick: false,
        showCloseButton: true,
      });
    }
    setShowLoaderOnSend(false);
    toggle();
  };

  const handleSendInvites = () => {
    if (availableCredits < creditsToBeUsed) {
      toggleCreditsModal();
    } else {
      toggle();
    }
  };

  return (
    <div>
      <Button
        className="btn-icon"
        color="warning"
        type="button"
        onClick={() => handleSendInvites()}
        // size="lg"
      >
        <span className="btn-inner--icon">
          <i className="ni ni-send" style={{ fontSize: "1.4rem" }} />
        </span>
        <span className="btn-inner--text text-white h3">
          Send Calendar Invites
        </span>
      </Button>

      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Send Calendar Invites</ModalHeader>
        <ModalBody>
          <Table borderless>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted h4">Number of Recipients:</span>
                </td>
                <td className="text-right pr-5">
                  <span className="text-black h4">
                    {convertCommaSeperateNumToString(numberOfRecipients)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-muted h4">Credits to be Used:</span>
                </td>
                <td className="text-right pr-5">
                  <span className="text-black h4">
                    {convertCommaSeperateNumToString(creditsToBeUsed)}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-muted h4">Available Credits:</span>
                </td>
                <td className="text-right pr-5">
                  <span className="text-black h4">
                    {convertCommaSeperateNumToString(availableCredits)}
                  </span>
                </td>
              </tr>
            </tbody>
          </Table>
          <p className="mt-3">
            Calendar invites will be sent to{" "}
            <strong>
              {convertCommaSeperateNumToString(numberOfRecipients)}
            </strong>{" "}
            attendees. This action will use{" "}
            <strong>{convertCommaSeperateNumToString(creditsToBeUsed)}</strong>{" "}
            credits from your total of{" "}
            <strong>{convertCommaSeperateNumToString(availableCredits)}</strong>{" "}
            credits.
          </p>
        </ModalBody>
        <div className="d-flex justify-content-end p-3">
          <Button color="secondary" onClick={toggle} className="me-2">
            Cancel
          </Button>
          <Button
            className="btn-icon"
            color="warning"
            type="button"
            onClick={() => {
              setShowLoaderOnSend(true);
              handleSend();
            }}
            disabled={availableCredits < creditsToBeUsed || showLoaderOnSend}
          >
            {showLoaderOnSend ? (
              <i className="fa fa-spinner fa-spin fa-lg text-white px-3" />
            ) : (
              <>
                <span className="btn-inner--icon">
                  <i className="ni ni-send" style={{ fontSize: "1.5rem" }} />
                </span>
                <span className="btn-inner--text text-white h3">Send</span>
              </>
            )}
          </Button>
        </div>
      </Modal>
      <Modal
        isOpen={creditsInsuficientModal}
        toggle={toggleCreditsModal}
        centered
      >
        <ModalHeader toggle={toggleCreditsModal}>
          {/* <i className="fa-solid fa-exclamation-triangle text-warning mr-2"></i> */}
          Insufficient Credits
        </ModalHeader>
        <ModalBody>
          <div className="d-flex justify-content-center">
            <i className="fa-solid fa-exclamation-triangle  text-warning mr-2 fa-3x"></i>
          </div>
          <Table borderless>
            <tbody>
              <tr>
                <td>
                  <span className="text-muted h4">Available Credits:</span>
                </td>
                <td>
                  <span className="text-black h4">{availableCredits}</span>
                </td>
              </tr>
              <tr>
                <td>
                  <span className="text-muted h4">Required Credits:</span>
                </td>
                <td>
                  <span className="text-black h4">{creditsToBeUsed}</span>
                </td>
              </tr>
            </tbody>
          </Table>
          <p className="font-weight-600  text-center">
            You have insufficient credits to send this calendar invites. Upgrade
            now to get more credits and unlock additional features.
          </p>
          <div className="d-flex justify-content-center p-3">
            <Button
              className="btn-icon"
              color="info"
              type="button"
              onClick={() => navigate("/a/plans")}
            >
              <span className="btn-inner--icon">
                <i className="fa-solid fa-arrow-up text-white fa-xl"></i>
              </span>
              <span className="btn-inner--text text-white h3">Upgrade Now</span>
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
