import { planList } from "action/Billing/plans";
import { createOrder } from "action/Billing/plans";
import { checkTopUpStatus } from "action/Billing/plans";
import { paypalTopUp } from "action/Billing/plans";
import { paypalPlanSubscription } from "action/Billing/plans";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

const PaypalButton = (props) => {
  const dispatch = useDispatch();
  useEffect(() => {
    // Load the PayPal SDK script dynamically
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${props.clientID}&vault=true&intent=${props.orderFlag}`;
    script.setAttribute("data-sdk-integration-source", "button-factory");
    script.onload = () => {
      if (window.paypal) {
        window.paypal
          .Buttons({
            style: {
              shape: "rect",
              color: "blue",
              layout: "vertical",
              label: "subscribe",
            },
            // Conditional actions for subscription or top-up
            ...(props.orderFlag === "subscription"
              ? {
                  createSubscription: function (data, actions) {
                    return actions.subscription.create({
                      plan_id: props.paypalPlanID,
                      custom_id: `${props.tenantID},${props.planID}`,
                    });
                  },
                }
              : {
                  createOrder: async function (data, actions) {
                    const requestData = {
                      purchasedPlanDetails: props.selectedPlan,
                      paymentGateway: "PAYPAL",
                      currency: "USD",
                      grandTotal: parseFloat(
                        props.selectedPlan
                          .reduce((total, plan) => total + (plan.price || 0), 0)
                          .toFixed(2)
                      ),
                    };

                    try {
                      const res = await paypalTopUp(requestData);
                      return res.data.OrderID;
                    } catch (error) {
                      throw new Error("Failed to create order");
                    }
                  },
                }),

            onApprove: async function (data, actions) {
              props.setLoading(true);
              if (props.orderFlag === "subscription") {
                const planData = {
                  razorpay_subscription_id: data.subscriptionID,
                  recurring: "YES",
                  subscription_id: data.subscriptionID,
                  paymentGateway: "PAYPAL",
                  plan_id: props.planID,
                };
                props.closeModal();
                try {
                  const res = await paypalPlanSubscription(planData);
                  props.setLoading(false);
                  if (res.code === 200) {
                    planList(dispatch);
                    props.notify("success", "Your Payment is Successful.");
                  } else {
                    props.notify(
                      "error",
                      "Something went wrong. Please try again."
                    );
                  }
                } catch (error) {
                  props.setLoading(false);
                  props.notify(
                    "error",
                    "An error occurred while processing your payment."
                  );
                }
              } else {
                actions.order.capture().then(async function (details) {
                  const paymentData = {
                    orderID: details.id,
                    paymentGateway: "PAYPAL",
                    paymentID:
                      details?.purchase_units[0]?.payments.captures[0]?.id,
                  };
                  props.closeModal();
                  const res = await checkTopUpStatus(paymentData);
                  props.setLoading(false);
                  if (res.code === 200) {
                    planList(dispatch);
                    props.notify("success", "Your Payment is Successful.");
                  } else {
                    props.notify(
                      "error",
                      "An error occurred while processing your payment."
                    );
                  }
                });
              }
            },
            onError: function (err) {
              props.closeModal();
            },
          })
          .render("#paypal-button-container");
      }
    };
    document.body.appendChild(script);
    // Cleanup the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return <div id="paypal-button-container"></div>;
};

export default PaypalButton;
